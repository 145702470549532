(function ($, Promise) {

    // Constructor
    function KidScreen(id) {

      // Save game id
      this.id = id;

      this.data = false;
      this.parentVideo = false;
      this.canvasEl = $("#kidscreen-canvas")[0];

      this.meterLeft = false;
      this.meterRight = false;

      this.direction = {x: 0, y: 0}
      this.cropCenterXNorm = 0.5;
      this.cropCenterYNorm = 0.5;

      this.ac = false;
      this.gainLeft = false;
      this.gainRight = false;
      this.cutoffTween = 0;
      this.cutoffTweenValue = 0;
      this.lastCutOff = 0;
      this.biquadFilter = false;

      this.analyser = false;
      this.selectedFilter = -1;

      // Load data then init
      this.loadData();
    }

    KidScreen.prototype.loadData = function () {

      // Load JSON
      var json1 = $.ajax({
        url: "data/livecam.json",
        dataType: 'json',
        cache: false,
        complete: $.proxy(function (data) {
          this.data = JSON.parse(data.responseText);
          this.mode = this.data.mode;
        }, this)
      });

      // Load JSON
      // var json2 = $.ajax({
      //   url: "data/world.json",
      //   dataType: 'json',
      //   cache: false,
      //   complete: $.proxy(function (data) {
      //     this.worldData = JSON.parse(data.responseText);
      //   }, this)
      // });

      // Wait for all
      Promise.all([
        json1.promise(),
        // json2.promise(),
      ]).then($.proxy(function () {
        this.initGame();
      }, this));

    };

    KidScreen.prototype.initGame = function () {

      // this.audio = new AudioPlayer(this);

      // Open perent frame
      this.openParentFrame();

      // Init direction control
      this.initDirectionControl();

      // Keyboard events
      $(window).bind("keydown", $.proxy(function (e) {
        if (e.keyCode == 27 && gui) {
          this.quit(); // Escape
        } else if ((e.keyCode == 73 || e.keyCode == 67) && gui) {
          nw.Window.get().showDevTools(); // Key I or C
        }
      }, this));


      // Create filters array
      this.biquadFilter = new Array(this.data.filters.length);

      // $(document).bind("mousemove", $.proxy(function (e) {
      //   // Update crop center
      //   this.cropCenterXNorm = e.clientX / $(document).width();
      //   this.cropCenterYNorm = e.clientY / $(document).anheight();
      // }, this));

      // $("#canvas-eq").bind("mousemove", $.proxy(function (e) {
      //   // Update crop center
      //   if (this.selectedFilter != -1) {
      //     this.biquadFilter[this.selectedFilter].frequency.value = (e.clientX / $("#canvas-eq").width()) * (app.ac.sampleRate / 2);
      //     this.biquadFilter[this.selectedFilter].gain.value = (e.clientY / $("#canvas-eq").height()) * -256;
      //     this.biquadFilter[this.selectedFilter].Q.value = this.biquadFilter[this.selectedFilter].frequency.value * 60;
      //   }
      // }, this));
      //
      // $("#canvas-eq").bind("click", $.proxy(function (e) {
      //   if (this.selectedFilter != -1) {
      //     console.log("index: " + this.selectedFilter + ", frequency: " + this.biquadFilter[this.selectedFilter].frequency.value + ", gain: " + this.biquadFilter[this.selectedFilter].gain.value + ", Q: " + this.biquadFilter[this.selectedFilter].Q.value);
      //   }
      //   this.selectedFilter = ((this.selectedFilter + 2) % (this.biquadFilter.length + 1)) - 1;
      // }, this));

      // Init parent camera
      this.getCameraFeedByName(this.data.cameras.parent.deviceName, this.data.cameras.parent.width, this.data.cameras.parent.height, $.proxy(function (media) {
        console.log("Parent camera connected.", media)
        this.parentVideo = media;
      }, this));

      // Init Microphone
      this.getMicrophoneFeedByName(this.data.microphone.deviceName, $.proxy(function (media) {
        console.log("Microphone connected.", media)
        this.microphone = media;
      }, this))

      this.animate();
    };

    KidScreen.prototype.openParentFrame = function () {

      var x = this.data.parentFrame.x;
      var y = this.data.parentFrame.y;

      this.openFrame("parent.html", 1920, 1080, x, y, $.proxy(function (new_win) {
        console.log("callback for controlFrameReady");
        this.controlFrame = new_win;
        this.controlFrame.app.initSharedObjects(this);
        this.initControlFrameEventListener(new_win);

        // Trigger replace window
        setInterval($.proxy(this.replaceWindow, this, x, y), 5000);

      }, this));
    };

    KidScreen.prototype.replaceWindow = function (x, y) {
      if (typeof nw != "undefined" && this.controlFrame != false) {
        // Set main size
        nw.Window.get().resizeTo(1920, 1080);
        // Set control frame size and position
        nw.Window.get(this.controlFrame).resizeTo(1920, 1080);
        nw.Window.get(this.controlFrame).moveTo(x, y);
      } else {
        console.log("no nwjs window (" + x + "," + y + ")");
      }
    }

    KidScreen.prototype.openFrame = function (url, width, height, x, y, callback) {

      if (typeof nw !== "undefined") {
        // Create a new window and get it
        nw.Window.open(url, {
          transparent: false,
          width: width,
          height: height,
          frame: false,
          focus: false,
          resizable: false,
          show: true,
          // always_on_top: true
          // new_instance: true // DOESN'T WORK TO DATE : https://github.com/nwjs/nw.js/issues/4418
        }, $.proxy(function (new_win) {

          // Add main window message listener
          window.addEventListener("message", $.proxy(function (event) {
            if (event.data == "ready") {
              callback(new_win.window);
            }
          }, this), false);

          // Add shild window load event listenermicrophone
          new_win.on('loaded', $.proxy(function () {
            new_win.moveTo(x, y);
            new_win.resizeTo(width, height);
          }, this));

        }, this));

      } else {
        // console.log("menubar=no,scrollbar=no,resizable=no,status=no,titlebar=no,height=" + height + ",width=" + width + ",left=" + x + ",top=" + y)
        var new_win = window.open(url, "_blank", "menubar=no,scrollbar=no,resizable=no,status=no,titlebar=no,height=" + height + ",width=" + width + ",left=" + x + ",top=" + y);
        $(new_win).on('load', $.proxy(function () {
          new_win.moveTo(x, y);
          window.addEventListener("message", $.proxy(function (event) {
            if (event.data == "ready") {
              callback(new_win);
            }
          }, this), false);
        }, this));
      }
    };

    KidScreen.prototype.getCameraFeedByName = function (name, width, height, callback) {

      navigator.mediaDevices.enumerateDevices()
        .then($.proxy(function (devices) {
          console.log("Video devices:");
          $(devices).each($.proxy(function (i, device) {
            // navigator.mediaDevices.getUserMedia({video: {deviceId: device.deviceId}});
            if (device.kind === 'videoinput') {
              console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
              if (device.label == name) {
                this.openCamera(device.deviceId, width, height, $.proxy(function (media) {
                  callback(media);
                }, this));
              }
            }
          }, this));
          console.log("---");
        }, this)).catch(function (err) {
        console.error(err.name + ": " + err.message);
      }).finally(function () {
        // console.log(cameras)
      });

    };

    KidScreen.prototype.getMicrophoneFeedByName = function (name, callback) {
      navigator.mediaDevices.enumerateDevices()
        .then($.proxy(function (devices) {
          console.log("Audio devices:");
          $(devices).each($.proxy(function (i, device) {
            // navigator.mediaDevices.getUserMedia({audio: {deviceId: device.deviceId}});
            if (device.kind === 'audioinput') {
              console.log(device.kind + ": " + device.label + " id = " + device.deviceId);

              // Define if this is the device we are lloking for using all name
              var isSameName = (device.label == name);
              if (Array.isArray(name) && ($.inArray(device.label, name) !== -1)) {
                isSameName = true;
              }

              if (isSameName) {
                this.openMicrophone(device.deviceId, $.proxy(function (media) {
                  callback(media);
                  console.log("there corresponding", device.label)
                }, this));
                console.log("here")
                return true;
              }
            }
          }, this));
          console.log("---");
        }, this)).catch(function (err) {
        console.error(err.name + ": " + err.message);
      }).finally(function () {
        // console.log(cameras)
      });

    };

    KidScreen.prototype.openCamera = function (deviceId, width, height, callback) {

      var constraints = {audio: false, video: {width: width, height: height, deviceId: deviceId}};
      var videoEl = false;
      var mediaStreamValue = false;

      navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStream) {
        mediaStreamValue = mediaStream;
        videoEl = document.createElement('video')
        videoEl.setAttribute('autoplay', '1')
        videoEl.setAttribute('width', width)
        videoEl.setAttribute('height', height)
        videoEl.setAttribute('style', 'display:none')
        videoEl.srcObject = mediaStream;
        document.body.appendChild(videoEl)
      }).catch(function (err) {
        console.error(err.name + ": " + err.message);
      }).finally(function () {
        if (typeof callback == "function") {
          callback({
            "videoEl": videoEl,
            "mediaStream": mediaStreamValue
          })
        }
      });

    }

    KidScreen.prototype.openMicrophone = function (deviceId, callback) {

      var constraints = {audio: {deviceId: deviceId, channelCount: 2}, video: false};
      var audioEl = false;
      var mediaStreamValue = false;

      navigator.mediaDevices.getUserMedia(constraints).then($.proxy(function (mediaStream) {
        mediaStreamValue = mediaStream;

        // grab an audio context
        this.ac = new AudioContext();

        var source = this.ac.createMediaStreamSource(mediaStream)
        var splitter = this.ac.createChannelSplitter(2);
        var merger = this.ac.createChannelMerger(2);

        if (this.biquadFilter.length > 0) {

          for (var i = 0; i < this.biquadFilter.length; i++) {
            // Create filter
            this.biquadFilter[i] = this.ac.createBiquadFilter();
            // https://developer.mozilla.org/en-US/docs/Web/API/BiquadFilterNode
            this.biquadFilter[i].type = this.data.filters[i].type;
            //The middle of the frequency range getting a boost or an attenuation.
            this.biquadFilter[i].frequency.value = this.data.filters[i].frequency;
            // The greater the Q value, the smaller the frequency band.
            this.biquadFilter[i].Q.value = this.data.filters[i].Q;
            // The boost, in dB, to be applied; if negative, it will be an attenuation.
            this.biquadFilter[i].gain.value = this.data.filters[i].gain;

            // Connect filters
            if (i == 0) {
              source.connect(this.biquadFilter[i]);
            } else {
              this.biquadFilter[i - 1].connect(this.biquadFilter[i]);
            }
          }

          var lastFilter = this.biquadFilter[this.biquadFilter.length - 1];

        } else {
          var lastFilter = source;
        }


        this.analyser = this.ac.createAnalyser();
        // source.connect(this.analyser);
        lastFilter.connect(this.analyser, 0, 0);
        this.freqs = new Uint8Array(this.analyser.frequencyBinCount);
        // this.times = new Uint8Array(this.analyser.frequencyBinCount);

        // Gain nodes
        this.gainLeft = this.ac.createGain();
        this.gainLeft.gain.setValueAtTime(this.data.microphone.volumeGain, this.ac.currentTime);
        this.gainRight = this.ac.createGain();
        this.gainRight.gain.setValueAtTime(this.data.microphone.volumeGain, this.ac.currentTime);

        // Create a new volume meter
        this.meterLeft = createAudioMeter(this.ac, 0.98, 0.1);
        this.meterRight = createAudioMeter(this.ac, 0.98, 0.1);

        // Route channel to ther node
        // source.connect(splitter);
        lastFilter.connect(splitter);
        splitter.connect(this.gainLeft, 0);
        splitter.connect(this.gainRight, 1);
        splitter.connect(this.meterLeft, 0, 0);
        splitter.connect(this.meterRight, 1, 0);

        if (this.data.microphone.reverseChannels == false) {
          this.gainLeft.connect(merger, 0, 0);
          this.gainRight.connect(merger, 0, 1);
        } else {
          this.gainLeft.connect(merger, 0, 1);
          this.gainRight.connect(merger, 0, 0);
        }

        merger.connect(this.ac.destination);

        if (mediaStream.getAudioTracks()[0].getSettings().channelCount <= 1) {
          // https://bugs.chromium.org/p/chromium/issues/detail?id=453876
          // https://www.chromestatus.com/features/schedule
          console.error("Couldn't get 2 channels form microphone. This feature is only available with Chrome 73+. https://bugs.chromium.org/p/chromium/issues/detail?id=453876")
        }

        // ac.createMediaElementSource(audioEl);

      }, this)).catch(function (err) {
        console.error(err.name + ": " + err.message);
      }).finally(function () {
        if (typeof callback == "function") {
          callback({
            "audioEl": audioEl,
            "mediaStream": mediaStreamValue
          })
        }
      });

    }

    KidScreen.prototype.initControlFrameEventListener = function () {

      // Close all opened cameras when page is reloaded or closed
      window.onunload = $.proxy(function () {
        if (this.controlFrame) {
          this.controlFrame.close();
        }
      }, this);

    }


    KidScreen.prototype.animate = function () {

      requestAnimationFrame($.proxy(this.animate, this));

      if (this.parentVideo) {

        var sw = this.data.crop.width;
        var sh = this.data.crop.height;
        var sx = (this.cropCenterXNorm * (this.parentVideo.videoEl.width - sw));
        var sy = (this.cropCenterYNorm * (this.parentVideo.videoEl.height - sh));

        // void ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
        var ctx = this.canvasEl.getContext('2d');
        ctx.save();
        if (this.data.cameras.parent.flip) {
          ctx.translate(this.canvasEl.width - 1, 0);
          ctx.scale(-1, 1);
        }
        ctx.drawImage(this.parentVideo.videoEl, sx, sy, sw, sh, 0, 0, this.canvasEl.width, this.canvasEl.height);
        ctx.restore();

        // Flip X when camera is flipped
        var flipMultiplicator = (this.data.cameras.parent.flip) ? -1 : 1;
        this.cropCenterXNorm = constrain((this.direction.x * 0.01 * flipMultiplicator + this.cropCenterXNorm), 0, 1);
        this.cropCenterYNorm = constrain((this.direction.y * 0.0177 + this.cropCenterYNorm), 0, 1);

      }

      if (this.analyser != false) {
        // this.drawEqAnalyszer();
      }

      // Cutoff volume when one is microphone is used
      if (this.ac != false) {
        // if (this.meterLeft.volume > this.data.microphone.leftCutOffThreshold) {
        //   // Left is used, cut-off right
        //   this.gainRight.gain.setValueAtTime(0, app.ac.currentTime);
        //   this.gainLeft.gain.setValueAtTime(this.data.microphone.volumeGain, app.ac.currentTime);
        //   this.lastCutOff = millis();
        // } else if (this.meterRight.volume > this.data.microphone.rightCutOffThreshold) {
        //   // Right is used, cut-off left
        //   this.gainRight.gain.setValueAtTime(this.data.microphone.volumeGain, app.ac.currentTime);
        //   this.gainLeft.gain.setValueAtTime(0, this.ac.currentTime);
        //   this.lastCutOff = millis();
        // } else if ((millis() - app.lastCutOff) > this.data.microphone.cutOffDuration) {
        //   this.gainLeft.gain.setValueAtTime(this.data.microphone.volumeGain, app.ac.currentTime);
        //   this.gainRight.gain.setValueAtTime(this.data.microphone.volumeGain, app.ac.currentTime);
        // }
        if (this.data.microphone.priority == "left") {

          if (this.meterRight.volume > this.data.microphone.rightThreshold ) {

            // Left priority, let right speak
            this.gainLeft.gain.setValueAtTime(0, app.ac.currentTime);
            this.gainRight.gain.setValueAtTime(this.data.microphone.volumeGain, app.ac.currentTime);
            this.lastCutOff = millis();
            if (this.cutoffTween != false) {
              this.cutoffTween.kill();
              this.cutoffTween = false;
            }
            this.cutoffState = "left priority, left on"

          } else if ((millis() - app.lastCutOff) > this.data.microphone.cutOffDuration ) {

            // Left priority, tween right speak
            if (this.cutoffTween == false) {
              this.cutoffTweenValue = 0;
              this.cutoffTween = TweenMax.to(this, 1, {cutoffTweenValue: this.data.microphone.volumeGain});
            }
            this.gainLeft.gain.setValueAtTime(this.cutoffTweenValue, app.ac.currentTime);
            this.gainRight.gain.setValueAtTime(0, app.ac.currentTime);
            this.cutoffState = "left priority, right on " + this.cutoffTweenValue;

          } else {
            this.cutoffState = "left priority, cutoff"
          }

        } else if (this.data.microphone.priority == "right") {

          if (this.meterLeft.volume > this.data.microphone.leftThreshold) {
            // Left is used, cut-off right
            this.gainRight.gain.setValueAtTime(0, app.ac.currentTime);
            this.gainLeft.gain.setValueAtTime(this.data.microphone.volumeGain, app.ac.currentTime);
            this.lastCutOff = millis();
            if (this.cutoffTween != false) {
              this.cutoffTween.kill();
              this.cutoffTween = false;
            }
            this.cutoffState = "right priority, left on"

          } else if ((millis() - app.lastCutOff) > this.data.microphone.cutOffDuration) {

            if (this.cutoffTween == false) {
              this.cutoffTweenValue = 0;
              this.cutoffTween = TweenMax.to(this, 1, {cutoffTweenValue: this.data.microphone.volumeGain});
            }
            this.gainLeft.gain.setValueAtTime(0, app.ac.currentTime);
            this.gainRight.gain.setValueAtTime(this.cutoffTweenValue, app.ac.currentTime);
            this.cutoffState = "right priority, right on " + this.cutoffTweenValue

          } else {
            this.cutoffState = "right priority, cutoff"
          }

        } else {
          this.cutoffState = "oops"
        }
      }

      if (this.meterLeft) {
        var realVolume = constrain(this.meterLeft.volume, 0, 1);
        var visualVolume = constrain((((Math.sin(realVolume * this.data.microphone.volumeMeterMultipicator * (Math.PI) - (Math.PI / 2))) + 1) / 2), 0, 1);
        $(".microphone-background-filler").css("top", (100 - (visualVolume * 100)) + "%");
      }
    }

    KidScreen.prototype.initDirectionControl = function () {

      $(document).on("touchmove mousemove", "#direction-container", $.proxy(function (e) {

        var isTouch = (e.type != "mousemove");

        if (!isTouch && this.mousedown == false) {
          return;
        } else {
          this.mousedown = true;
        }

        var x = (isTouch ? e.originalEvent.touches[0].pageX : e.pageX) - $(e.currentTarget).offset().left;
        var y = (isTouch ? e.originalEvent.touches[0].pageY : e.pageY) - $(e.currentTarget).offset().top;
        var trackWidth = $(e.currentTarget).width();
        var trackHeight = $(e.currentTarget).width();
        var normalizedX = constrain((((x / trackWidth) * 2) - 1), -1, 1);
        var normalizedY = constrain((((y / trackHeight) * 2) - 1), -1, 1);

        if (Math.sqrt(Math.pow(normalizedX, 2) + Math.pow(normalizedY, 2)) > 1) {
          var radians = Math.atan2(normalizedY, normalizedX)
          normalizedX = Math.cos(radians);
          normalizedY = Math.sin(radians);
        }

        $("#direction-joystick").css({
          left: ((normalizedX + 1) / 2 * 100) + "%",
          top: ((normalizedY + 1) / 2 * 100) + "%",
        });

        // this.sendMessage("direction", {x: normalizedX, y: normalizedY})
        this.direction = {x: normalizedX, y: normalizedY}

      }, this)).on("touchend mouseup", $.proxy(function (e) {


        // Update joystick position
        $("#direction-joystick").css({
          left: "50%",
          top: "50%",
        });

        // Send message to master
        if (this.mousedown) {
          // this.sendMessage("direction", {x: 0, y: 0})
          this.direction = {x: 0, y: 0}
        }

        this.direction = {x: 0, y: 0}

        this.mousedown = false;

      }, this)).on("mousedown touchstart", "#direction-container", $.proxy(function (e) {

        this.mousedown = true;

      }, this));

    }

    KidScreen.prototype.drawEqAnalyszer = function () {

      var WIDTH = 1920;
      var HEIGHT = 360;
      var SMOOTHING = 0.8;
      var FFT_SIZE = 128;

      this.analyser.smoothingTimeConstant = SMOOTHING;
      this.analyser.fftSize = FFT_SIZE;

      // Get the frequency data from the currently playing music
      this.analyser.getByteFrequencyData(this.freqs);
      // this.analyser.getByteTimeDomainData(this.times);

      var width = Math.floor(1 / this.freqs.length, 10);

      var canvas = $('#canvas-eq')[0];
      var drawContext = canvas.getContext('2d');
      canvas.width = WIDTH;
      canvas.height = HEIGHT;
      drawContext.fillStyle = 'black';
      drawContext.fillRect(0, 0, WIDTH, HEIGHT);

      // Draw the frequency domain chart.
      for (var i = 0; i < this.analyser.frequencyBinCount; i++) {
        var value = this.freqs[i];
        var percent = value / 256;
        var height = HEIGHT * percent;
        var offset = HEIGHT - height - 1;
        var barWidth = WIDTH / this.analyser.frequencyBinCount;
        var hue = i / this.analyser.frequencyBinCount * 360;
        drawContext.fillStyle = 'hsl(' + hue + ', 100%, 50%)';
        drawContext.fillRect(i * barWidth, offset, barWidth, height);

        var hz = i * (app.ac.sampleRate / this.analyser.frequencyBinCount) / 2;
        // drawContext.text
        drawContext.fillStyle = 'white';
        drawContext.font = '10px serif';
        drawContext.fillText((hz > 1000 ? Math.round(hz / 1000) : hz), i * barWidth, 10);
      }

      // Draw filters
      var size = 5;
      for (var i = 0; i < this.biquadFilter.length; i++) {
        if (this.selectedFilter == i) {
          drawContext.fillStyle = 'rgb(255,0,0)';
        } else {
          drawContext.fillStyle = 'rgb(255,255,255)';
        }
        drawContext.beginPath();
        drawContext.ellipse(this.biquadFilter[i].frequency.value / (app.ac.sampleRate / 2) * WIDTH, (this.biquadFilter[i].gain.value / -256 * HEIGHT), size, size, 0, 0, Math.PI * 2);
        drawContext.fill()
      }

      // // Draw the time domain chart.
      // for (var i = 0; i < this.analyser.frequencyBinCount; i++) {
      //   var value = this.times[i];
      //   var percent = value / 256;
      //   var height = HEIGHT * percent;
      //   var offset = HEIGHT - height - 1;
      //   var barWidth = WIDTH / this.analyser.frequencyBinCount;
      //   drawContext.fillStyle = 'white';
      //   drawContext.fillRect(i * barWidth, offset, 1, 2);
      // }
    }

    KidScreen.prototype.quit = function () {

      // Close control frame
      if (this.controlFrame) {
        this.controlFrame.close();
      }

      // Request app quit
      if (gui) {
        gui.App.quit();
      }
    };

    KidScreen.prototype.reset = function () {

    };

    // Expose KidScreen
    window.KidScreen = KidScreen;

  }

)
(jQuery, Promise);
