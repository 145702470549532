(function ($, Promise) {

  // Constructor
  function ParentScreen() {

    this.main = false;
    this.selfCanvasEl = $("#parentscreen-canvas-self")[0];
    this.selfCanvasElCtx = this.selfCanvasEl.getContext('2d');
    this.kidCanvasEl = $("#parentscreen-canvas-kid")[0];
    this.kidCanvasElCtx = this.kidCanvasEl.getContext('2d');

    // Load data then init
    this.loadData();
  }

  ParentScreen.prototype.loadData = function () {

    // Load JSON
    var json1 = $.ajax({
      url: "data/livecam.json",
      dataType: 'json',
      cache: false,
      complete: $.proxy(function (data) {
        this.data = JSON.parse(data.responseText);
      }, this)
    });

    // Wait for all
    Promise.all([
      json1.promise(),
    ]).then($.proxy(function () {
      this.initGame();
    }, this));

  };

  ParentScreen.prototype.initGame = function () {

    if (window.opener) {
      window.opener.postMessage("ready", "*");
    }

    // Keyboard events
    $(window).bind("keydown", $.proxy(function (e) {
      if (e.keyCode == 27 && window.opener) {
        window.opener.app.quit();
      }
    }, this));

  };


  ParentScreen.prototype.initSharedObjects = function (p) {

    // Save shared objects
    this.main = p;

    // Init kid camera
    this.main.getCameraFeedByName(this.data.cameras.kid.deviceName, this.data.cameras.kid.width, this.data.cameras.kid.height, $.proxy(function (media) {
      console.log("Parent camera connected.", media)
      this.kidVideo = media;
    }, this));

    // Init render loop
    this.animate();
  }

  ParentScreen.prototype.animate = function () {
    requestAnimationFrame($.proxy(this.animate, this));

    // Draw kid camera
    if (this.main) {
      this.selfCanvasElCtx.drawImage(this.main.canvasEl, 0, 0, this.main.canvasEl.width, this.main.canvasEl.height, 0, 0, this.selfCanvasEl.width, this.selfCanvasEl.height);
    }

    // Draw parent camera
    if (this.kidVideo) {
      this.kidCanvasElCtx.save();
      if (this.data.cameras.kid.flip) {
        this.kidCanvasElCtx.translate(this.kidCanvasEl.width - 1, 0);
        this.kidCanvasElCtx.scale(-1, 1);
      }
      this.kidCanvasElCtx.drawImage(this.kidVideo.videoEl, 0, 0, this.data.cameras.kid.width, this.data.cameras.kid.height, 0, 0, this.kidCanvasEl.width, this.kidCanvasEl.height);
      this.kidCanvasElCtx.restore();
    }

    // Update volume meter
    if (this.main.meterRight) {
      var realVolume = constrain(this.main.meterRight.volume, 0, 1);
      var visualVolume = constrain((((Math.sin(realVolume * this.data.microphone.volumeMeterMultipicator * (Math.PI) - (Math.PI / 2))) + 1) / 2), 0, 1);
      $(".microphone-background-filler").css("top", (100 - (visualVolume * 100)) + "%");
    }

  }

  ParentScreen.prototype.sendMessage = function (type, parameter, id) {
    console.info("Send message to main frame (todo)", type, parameter, id)

    if (window.opener) {
      window.opener.postMessage({
        type: type,
        parameter: parameter,
        id: id,
      }, "*");
    }

  };

  ParentScreen.prototype.reset = function () {

  };


  // Expose ParentScreen
  window.ParentScreen = ParentScreen;

})(jQuery, Promise);
